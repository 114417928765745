<template>
  <div v-if="!progress">
    <a-card
      :editable="false"
      header="Upload CSV file with ; delimiter"
    >
      <b-field
        class="file"
      >
        <b-upload
          v-model="file"
          @input="uploadFile"
        >
          <a class="button is-primary">
            <b-icon icon="upload" />
            <span>Choose a file</span>
          </a>
        </b-upload>
        <span
          v-if="file"
          class="file-name"
        >
          {{ file.name }}
        </span>
      </b-field>
      <div
        v-if="!success"
      >
        <b-button
          v-if="!checked"
          @click="checkUnknownSuppliers"
        >
          Check and add suppliers
        </b-button>

        <b-button
          v-if="checked"
          :disabled="adding"
          :loading="adding"
          type="is-success"
          @click="addToParts"
        >
          Add to parts
        </b-button>
      </div>
    </a-card>

    <!--   <a-card
  v-if="unknownSuppliers.length"
  :editable="false"
  header="Unknown Suppliers"
  >
    <ul class="menu-list">
      <li
        v-for="supplier in unknownSuppliers"
        class="list-item"
        :key="supplier"
      >
        {{ supplier }}
      </li>
    </ul>
  </a-card> -->

    <a-table
      :data="items"
      :columns="user.routes.parts.columns"
      hide-actions
    />
  </div>
  <b-progress
    v-else
    :value="progress"
    :type="progress === 100 ? 'is-success' : 'is-default'"
    show-value
    format="percent"
  >
    {{ progress === 100 ? 'Upload completed succesfully' : `${progress} % completed` }}
  </b-progress>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import csv from 'csvtojson'

export default {
  data () {
    return {
      file: null,
      progress: 0,
      items: [],
      unknownSuppliers: [],
      checked: false,
      success: false,
      adding: false
    }
  },
  computed: {
    ...mapGetters({
      suppliers: 'suppliers/items'
    }),
    newSupplierNames() {
      return _.keys(_.groupBy(_.filter(this.items, item => item.newSupplier), 'newSupplier'))
    }
  },
  async created() {
    await this.$store.dispatch('suppliers/fetchAll')
  },
  methods: {
    uploadFile (file) {
      let self = this
      console.log('file', file)
      var reader = new FileReader()
      reader.readAsText(file, "UTF-8")
      reader.onload = async function() {
        const data = await csv({
          delimiter: ';',
          headers: ['id', 'description', 'name', 'newSupplier']
        }).fromString(reader.result)
        console.log('data', data)
        self.items = data
        /*self.$buefy.toast.open({
          duration: 3000,
          message: `Successfully read ${self.items.length} parts`,
          position: 'is-bottom',
          type: 'is-success'
        })*/
      }
    },
    checkUnknownSuppliers() {
      const self = this

      this.unknownSuppliers = []

      _.forEach(this.newSupplierNames, newSupplierName => {
        if(!_.find(this.suppliers, supplier => _.toLower(supplier.name) === _.toLower(newSupplierName))) {
          this.unknownSuppliers.push(newSupplierName)
        }
      })

      if(this.unknownSuppliers.length) {
        let suppliersString = '<h3>Please add these suppliers to database to continue</h3>'
        _.forEach(this.unknownSuppliers, supplierName => suppliersString += `${supplierName.toString()}<br/>`)
        this.$buefy.dialog.confirm({
          title: 'Unknown suppliers',
          message: suppliersString,
          confirmText: 'Done',
          onConfirm: () => self.checkUnknownSuppliers()
        })
      } else {
        this.$buefy.toast.open({
          duration: 3000,
          message: `Successfully read ${self.items.length} parts`,
          position: 'is-bottom',
          type: 'is-success'
        })
        this.addSuppliers()

      }

    },
    addSuppliers() {
      _.forEach(this.items, newPart => {
        if(newPart.newSupplier) {
          let supplier = _.find(this.suppliers, supplier => _.toLower(supplier.name) === _.toLower(newPart.newSupplier))
          if(supplier) {
            Vue.set(newPart, 'supplier', supplier)
          } else {
            Vue.set(newPart, 'supplier', null)
          }
          Vue.set(newPart, 'id', newPart.id.replace(/\./g, '-'))
        }
      })
      this.checked = true
    },
    async addToParts() {
      this.adding = true
      _.forEach(this.items, newPart => {
        _.unset(newPart, 'newSupplier')
        // this.$store.dispatch('parts/set', newPart)
      })
      const result = await this.$store.dispatch('parts/insertBatch', this.items)
      this.$buefy.toast.open({
        duration: 30000,
        message: `Imported ${result.length} parts`,
        position: 'is-bottom',
        type: 'is-success'
      })
      this.adding = false
      if(result.length) {
        this.success = true
      }
    }
  }
}
</script>
